import { ExperimentContext } from '@/common/context/ExperimentContext';
import { FC, useContext } from 'react';
import useInAppBrowser from '../../hooks/useInAppBrowser';
import ResponsiveContainer from '../ResponsiveContainer';
import BottomBar from './BottomBar';
import LinkBar from './LinkBar';
import TopBar from './TopBar';
import USPBar from './USPBar';

const Footer: FC = () => {
  const isInAppBrowser = useInAppBrowser();
  const { closure } = useContext(ExperimentContext);

  if (isInAppBrowser || closure.isInVariant) {
    return null;
  }
  return (
    <footer>
      <USPBar />
      <TopBar />
      <ResponsiveContainer>
        <LinkBar />
        <BottomBar />
      </ResponsiveContainer>
    </footer>
  );
};

export default Footer;
