import { ExperimentContext } from '@/common/context/ExperimentContext';
import { BodyText, Heading5 } from '@/modules/design/components/textstyles';
import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, FC, useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../context/LanguageContext';
import { StaticDataContext } from '../../context/StaticDataContext';
import { getHelpCenterUrl, getVacanciesUrl } from '../../utils/externalUrls';
import { buildPath, buildQueryParams } from '../../utils/filters';

const Container = styled.section`
  display: flex;
  margin: 64px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    flex-direction: column;
    margin: 48px 0;
  }
`;

const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    align-items: flex-end;

    > div {
      min-width: 180px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
      align-items: flex-start;
      margin-bottom: 0;

      > div {
        min-width: none;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    align-items: flex-start;
    margin-bottom: 48px;
  }
`;

const LinkTitle = styled(Heading5)`
  margin-bottom: 8px;
`;

const LinkListItem = styled.li`
  margin-top: 8px;
`;

const LinkAnchor = styled(BodyText)`
  &:hover {
    color: ${({ theme }) => theme.colors.CalypsoCP1};
  }
`;

const LinkBar: FC = () => {
  const { language, translate } = useContext(LanguageContext);
  const { categories, findCategoryById } = useContext(StaticDataContext);
  const { preComms } = useContext(ExperimentContext);

  const sections: {
    title: string;
    links: {
      title: string;
      linkProps?: LinkProps;
      url?: string;
      anchorProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
    }[];
  }[] = [
    {
      title: translate('footer_help_and_information'),
      links: [
        {
          title: translate('help_center'),
          url: getHelpCenterUrl(language),
          anchorProps: { rel: 'nofollow', target: '_blank' },
        },
        {
          title: translate('tscs_tscs'),
          linkProps: {
            href: '/terms-and-conditions',
          },
        },
        {
          title: translate('tscs_privacy_policy'),
          linkProps: {
            href: '/privacy-statement',
          },
        },
        {
          title: translate('tscs_cookie_statement'),
          linkProps: {
            href: '/cookie-statement',
          },
        },
      ],
    },
    {
      title: translate('footer_other'),
      links: [
        {
          title: translate('about_about_us'),
          linkProps: {
            href: '/about',
          },
        },
        {
          title: translate('blog_blog'),
          linkProps: {
            href: '/blog',
          },
        },
        {
          title: translate('about_vacancies'),
          url: getVacanciesUrl(),
          anchorProps: { rel: 'nofollow', target: '_blank' },
        },
        {
          title: preComms.isInVariant
            ? translate('login_login')
            : translate('login_register'),
          linkProps: {
            href: preComms.isInVariant ? '/login' : '/register',
          },
        },
      ],
    },
    {
      title: translate('gen_products'),
      links: [
        ...categories.map((category) => ({
          title: category.title,
          linkProps: {
            href: {
              pathname: '/feed',
              query: buildQueryParams({ categoryId: category.id }),
            },
            as: {
              pathname: buildPath(
                language,
                { categoryId: category.id },
                findCategoryById
              ),
            },
          },
        })),
        // {
        //   title: translate('gen_brands'),
        //   linkProps: {
        //     href: '/brands',
        //     as: `/${language}/brands`,
        //   },
        // },
      ],
    },
  ];
  return (
    <Container>
      {sections.map((section, index) => (
        <LinkSection key={index}>
          <div>
            <LinkTitle>{section.title}</LinkTitle>
            <ul>
              {section.links.map((link, innerIndex) => (
                <LinkListItem key={innerIndex}>
                  {link.url ? (
                    <LinkAnchor as={'a'} href={String(link.url)}>
                      {link.title}
                    </LinkAnchor>
                  ) : (
                    <Link {...link.linkProps} passHref>
                      <LinkAnchor as={'a'}>{link.title}</LinkAnchor>
                    </Link>
                  )}
                </LinkListItem>
              ))}
            </ul>
          </div>
        </LinkSection>
      ))}
    </Container>
  );
};

export default LinkBar;
