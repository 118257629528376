import { FC } from 'react';
import styled, { css } from 'styled-components';
import {
  getAppStoreUrl,
  getFacebookUrl,
  getInstagramUrl,
  getLinkedInUrl,
  getPlayStoreUrl,
} from '../../utils/externalUrls';
import ResponsiveContainer from '../ResponsiveContainer';

interface SocialProps {
  image: string;
  url: string;
  title: string;
}

const SocialContainer = styled.div`
  display: flex;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    justify-content: center;
  }
`;

const AppContainer = styled.div`
  display: flex;

  a {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    height: 34px;
  }

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    margin-top: 24px;
    justify-content: center;
  }
`;

const ListAnchor = styled.a`
  margin: 0 8px;

  &:first-child {
    margin: 0 8px 0 0;
  }

  &:last-child {
    margin: 0 0 0 8px;
  }
`;

const SocialButton: FC<SocialProps> = ({ image, url, title: name }) => (
  <ListAnchor href={url} rel="me nofollow" target="_blank">
    <img src={image} alt={name} />
  </ListAnchor>
);

const socialPlatforms: SocialProps[] = [
  {
    url: getFacebookUrl(),
    title: 'Facebook',
    image: require('@/modules/assets/icons/footer-fb.svg'),
  },
  {
    url: getLinkedInUrl(),
    title: 'LinkedIn',
    image: require('@/modules/assets/icons/footer-li.svg'),
  },
  {
    url: getInstagramUrl(),
    title: 'Instagram',
    image: require('@/modules/assets/icons/footer-ig.svg'),
  },
];

const TopBar: FC = () => (
  <Container>
    <SocialContainer>
      {socialPlatforms.map((platform, index) => (
        <SocialButton key={index} {...platform} />
      ))}
    </SocialContainer>
    <AppContainer>
      <a href={getAppStoreUrl()} rel="noreferrer" target="_blank">
        <img src={require('@/modules/assets/icons/app-store-badge.svg')} />
      </a>
      <a href={getPlayStoreUrl()} rel="noreferrer" target="_blank">
        <img src={require('@/modules/assets/icons/google-play-badge.svg')} />
      </a>
    </AppContainer>
  </Container>
);

export default TopBar;

const containerInnerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    flex-direction: column;
    align-items: normal;
  }
`;

const Container = styled(ResponsiveContainer).attrs({
  innerStyle: containerInnerStyle,
})`
  background-color: #f7f9fa;
`;
