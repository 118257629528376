const LANGUAGE_CODES = ['en', 'fr'];
const currentLanguage = 'en';
const pattern = /\[(\/)?(b|h|i|uri|url)(?: (href)=['"]([^'"]*)['"])?\]/gi;

export const bbcodeToHtml = (input: string) => {
  if (!input) {
    return '';
  }
  // Apply the regex to the input
  return input.replace(pattern, (_, close, type, attribute, value) => {
    // For each result, generate a HTML tag
    let result = '<';
    if (close) {
      result += '/';
    }

    // Check which type is requested
    switch (type) {
      case 'i':
      case 'b':
        result += type;
        break;
      case 'h':
        result += 'span';
        attribute = 'class';
        value = 'highlight';
        break;
      case 'uri':
        result += 'a';
        if (attribute === 'href' && value !== null) {
          const regex = new RegExp('^/(' + LANGUAGE_CODES.join() + ')/');
          if (!regex.test(value)) {
            value = '/' + currentLanguage + value;
          }
        } else if (!close) {
          // Invalid attribute
          return '';
        }
        break;
      case 'url':
        result += 'a target="_blank"';
        if (attribute === 'href' && value !== null) {
          break;
        }

        if (!close) {
          // Invalid attribute
          return '';
        }
        break;
      default:
        break;
    }

    // Check if the HTML tag has an attribute set
    if (attribute) {
      result += ' ' + attribute;
      if (value) {
        result += '="' + value + '"';
      }
    }

    // Close the HTML tag
    result += '>';

    return result;
  });
};
