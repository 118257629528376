import { Heading5 } from '@/modules/design/components/textstyles';
import { rgba, transparentize } from 'polished';
import { FC, useContext } from 'react';
import styled, { css } from 'styled-components';
import { LanguageContext } from '../../context/LanguageContext';
import { bbcodeToHtml } from '../../lib/bbcode';
import ResponsiveContainer from '../ResponsiveContainer';

const USP = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  position: relative;
  padding: 40px 16px;

  > img {
    display: block;
    margin: 0 auto 20px;
  }

  &:hover {
    > p {
      visibility: visible;
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  p {
    color: ${({ theme }) => transparentize(0.25, theme.colors.CG1)};
    font-size: 1.6rem;
    line-height: 2.4rem;
    position: absolute;
    line-height: 1.2;
    width: 320px;
    padding: 25px 30px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 15px 27px ${rgba('#000', 0.23)};
    bottom: calc(100% - 35px);
    left: calc(50% - 160px);
    visibility: hidden;
    transform: translate(0, 10px);
    opacity: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      visibility: visible;
      opacity: 1;
    }

    &::after {
      content: ' ';
      width: 20px;
      height: 20px;
      position: absolute;
      left: calc(50% - 10px);
      border: 10px solid transparent;
      border-top-color: #fff;
      bottom: -20px;
    }
  }
`;

const USPBar: FC = () => {
  const { translate } = useContext(LanguageContext);
  return (
    <ResponsiveContainer innerStyle={containerInnerStyle}>
      <USP>
        <img
          src={require('@/modules/assets/icons/reliable-refund-policy.svg')}
          alt={translate('gen_usp_refund')}
        />
        <Heading5
          dangerouslySetInnerHTML={{
            __html: bbcodeToHtml(translate('gen_usp_refund')),
          }}
        />
      </USP>

      <USP>
        <img
          src={require('@/modules/assets/icons/safe-payments.svg')}
          alt={translate('gen_usp_secure')}
        />
        <Heading5
          dangerouslySetInnerHTML={{
            __html: bbcodeToHtml(translate('gen_usp_secure')),
          }}
        />
      </USP>

      <USP>
        <img
          src={require('@/modules/assets/icons/support.svg')}
          alt={translate('gen_usp_replica')}
        />
        <Heading5
          dangerouslySetInnerHTML={{
            __html: bbcodeToHtml(translate('gen_usp_replica')),
          }}
        />
      </USP>
    </ResponsiveContainer>
  );
};

export default USPBar;

const containerInnerStyle = css`
  display: flex;
  margin: 40px 0;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    flex-direction: column;
  }
`;
