import { Caption2 } from '@/modules/design/components/textstyles';
import LanguageMenu from '@/modules/menu/components/LanguageMenu';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from '../../context/LanguageContext';
import { getMasterCardUrl, getVisaUrl } from '../../utils/externalUrls';

const Container = styled.section`
  display: flex;
  margin-bottom: 64px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PaymentProviders = styled.ul`
  flex: 1;
  display: flex;
  flex-wrap: wrap;

  > li {
    margin: 0 12px 12px 0;
  }

  img {
    display: block;
    height: 36px;
  }
`;

const Copyright = styled(Caption2)`
  flex: 1;
  text-align: center;
  margin-bottom: 12px;

  @media (max-width: ${({ theme: { breakpoints } }) =>
      breakpoints.mobileMaxWidth}) {
    margin: 12px 0 24px 0;
    text-align: left;
  }
`;

const LanguageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const paymentProviders: { title: string; image: string; url: string }[] = [
  {
    title: 'Visa',
    image: require('@/modules/assets/icons/visa.svg'),
    url: getVisaUrl(),
  },
  {
    title: 'Master Card',
    image: require('@/modules/assets/icons/master-card.svg'),
    url: getMasterCardUrl(),
  },
];

const BottomBar: FC = () => {
  const { translate } = useContext(LanguageContext);

  return (
    <Container>
      <PaymentProviders>
        {paymentProviders.map(({ url, image, title }) => (
          <li key={title}>
            <a href={url} rel="noreferrer" target="_blank">
              <img src={image} alt={title} />
            </a>
          </li>
        ))}
      </PaymentProviders>
      <Copyright>
        {translate('gen_copyright', new Date().getFullYear())}
      </Copyright>
      <LanguageContainer>
        <LanguageMenu
          compact={false}
          alignContextMenu={'top-right'}
          mobileAlignContextMenu={'top-left'}
        />
      </LanguageContainer>
    </Container>
  );
};

export default BottomBar;
